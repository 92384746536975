html {
  /* font-size: 62.5%; */
  /* =10px */
}

.clickable {
  cursor: pointer;
}

.fs-14 {
  font-size: 14px;
  /* font-size: 1.4rem; */
}

#sidebar a {
  color: white;
}
