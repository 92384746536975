@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 300;
  src: url('assets/fonts/NotoSansJP-Regular.otf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans JP';
  font-style: bold;
  font-weight: 600;
  src: url('assets/fonts/NotoSansJP-Bold.otf') format('truetype');
}

body {
  margin: 0;
  font-family: 'Noto Sans JP', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  outline: none;
  border: none;
  background: none;
}